<template>

<div class="main product" :class="sectionsClass" v-if="!loading">
	<div v-if="showBackToList" href="#" class="back-to-list" @click="backToList()"><i class="fas fa-chevron-left"></i> Back to List</div>
	<h1>{{ product.title }}</h1>
	<div v-if="showProductNotFound" class="empty-list" style="margin: 2em;">
		Product Not Found
	</div>
	<div v-else class="product-detail two-one-columns">
		<div class="column first">
			<div class="image-wrapper">
				<img :src="product.image_src" :alt="product.description">
				<div v-if="product.display_flag" class='display_flag'><img :src="product.display_flag" alt='Display Flag'></div>
			</div>
			<div style="font-size: 0.8em;">Image and details may not match the actual product sold. See store policy for return/restock fees.</div>
			<div class="meta">
				<div class="label">Available</div>
				<div v-if="product.store_available > 0">
					In Store
				</div>
				<div v-else-if="product.warehouse_available">
					At Warehouse
				</div>
				<div v-else>
					Currently Unavailable
				</div>
				<div v-if="product.msrp" class="label">MSRP</div>
				<div v-if="product.msrp" v-html="$formatPrice(product.msrp)"></div>
				<template v-if="product.exception_lists && product.exception_lists.length > 0">
					<div class="label">Restricted</div>
					<div class="exception-list">
						<span v-for="(exception, index) in product.exception_lists" :key="'exception-' + index">{{ exception }}<template v-if="index < product.exception_lists.length - 1">, </template></span>
					</div>
				</template>
			</div>
			<div class="description">
				<h2>{{ product.description }}</h2>
				<p v-html="product.detailed_description"></p>
			</div>
			<h3 v-if="product_notices.length > 0">Notice</h3>
			<div class="product-notices" v-for="(product_notice, index) in product_notices">
				<div>{{ product_notice.description }}</div>
				<div v-html="product_notice.notice"></div>
			</div>
		</div>
		<div class="column last">
			<div class="columns columns-1">
				<div class="price" v-html="$formatPrice(product.price)" v-if="product.price"></div>
				<div class="financing-available" v-if="product && product.min_monthly_payment">
					<!-- <p v-if="!getCredovaApprovalAmount">
						Financing available as low as<br>${{ product.min_monthly_payment }} per month.
					</p>
					<p v-else>
						Financing available for up to ${{ getCredovaApprovalAmount }}.
						<span v-if="getCredovaUserPublicId">You're prequalified!</span>
					</p> -->

					<p class="credova-button" :data-amount="product.price" data-type="popup" data-hide-brand="true" data-message="Financing available as low as %0 per month! Learn more..."></p>

					<div class="controls">
						<!-- Comment out next line and uncomment the next one if using "Apply now" button -->
						<!-- Prequalify at checkout. -->
						<!-- <div v-if="!getCredovaUserPublicId" class="button action" style="padding: 0.75em;" @click="openPreAuthModal()">Prequalify now</div> -->
						<!-- Also uncomment code in mounted below -->
						<!-- <div style="margin: 0.5em 0 0 0; text-decoration: underline; cursor: pointer;" @click="showFinancingModal = true">How does financing work?</div> -->
					</div>
				</div>
				<div class="controls">
					<button v-if="product.call_to_order" class="button primary" @click="$router.push('/contact')">Call To Order</button>
					<button v-else-if="product.price && product.available && !product.in_cart" :class="['button', addingToCart ? 'disabled' : 'action', 'add-to-cart']" @click="addToCart()">Add to Cart</button>
					<router-link tag="button" to="/cart" class="button action go-to-cart" v-if="product.price && product.in_cart">View Cart</router-link>
					<button class="button disabled not-available" v-if="!product.price || product.available < 1">Unavailable</button>
				</div>
			</div>
			<h3 @click="hideOne = !hideOne" style="cursor: pointer; user-select: none;"><i class="fas fa-plus"></i> Item Info</h3>
			<table class="item-info" cellspacing="0" v-show="!hideOne">
				<tr>
					<td class="label">Item</td><td>{{ product.product_id }}<span v-if="product.distributor_id">-{{ product.distributor_id }}</span></td>
				</tr>
				<tr>
					<td class="label">UPC</td><td>{{ product.upc }}</td>
				</tr>
				<tr>
					<td class="label">Brand</td><td>{{ product.manufacturer }}</td>
				</tr>
				<tr>
					<td class="label">Model</td><td>{{ product.model }}</td>
				</tr>
				<tr>
					<td class="label">Mfg No</td><td>{{ product.sku }}</td>
				</tr>
				<tr>
					<td class="label">Caliber</td><td>{{ product.caliber }}</td>
				</tr>
			</table>
			<div v-if="product.specifications && product.specifications.length > 0">
				<h3 @click="hideTwo = !hideTwo" style="cursor: pointer; user-select: none;"><i class="fas fa-plus"></i> Tech Specs</h3>
				<table class="tech-specs" cellspacing="0" v-show="!hideTwo">
					<tr v-for="(specification, index) in product.specifications" :key="'tech-specs-' + index">
						<td class="label">{{ specification.label }}</td>
						<td>{{ specification.value }}</td>
					</tr>
				</table>
			</div>

			<div class="controls">
				<h3 class="pointer" @click="printWindow()" style="margin: 0 0 1em 0;"><i class="fa fa-print"></i> Print Item</h3>
				<h3 v-if="!product.call_to_order" class="pointer" style="margin-bottom: 0.5em;" @click="wishListModalOpen = true"><i class="fa fa-box"></i> Save for Later</h3>
				<div v-for="(wishlist, wishListId) in wishLists" style="margin: 0 0 0.5em 1.5em;">
					<div v-if="inWishLists.includes(parseInt(wishListId))" class="pointer" @click="goToWishList(wishListId)">
						<i class="fa fa-check"></i> {{ wishlist }}
					</div>
					<div v-else class="pointer" @click="addToWishList(wishListId)">
						<i class="fa fa-plus"></i> {{ wishlist }}
					</div>
				</div>
			</div>

		</div>
	</div>
    <loading class="related-products" v-if="loadingRelatedProducts">Loading related items...<br></loading>
	<div class="related-products" v-else-if="relatedProducts && relatedProducts.length > 0">
		<h2>Related Items in {{ product.department }} / {{ product.category }}</h2>
    	<div class="section product-grid" style="margin-bottom: 0;">
			<product :product="product" v-for="(product, index) in relatedProducts" :editing="false" :key="'related-product-' + index"></product>
		</div>
	</div>
	<wish-list-modal @cancel="wishListModalOpen = false" :productId="product.product_id" @itemAdded="itemAdded" v-if="wishListModalOpen"></wish-list-modal>

	<div v-if="showFinancingModal" class="modal financing-modal">
		<div class="inner">
			<i class="fas fa-times" @click="showFinancingModal = false"></i>
			<p>We now offer financing from Credova making your next purchase easier than ever.  Look for the Financing option on the Payment screen during checkout.</p>
			<h3>Buy Now, Pay Later</h3>
			<p>Credova puts the purchasing power in your hands with an easy monthly payment plan instead of paying for your entire purchase upfront.</p>
			<h3>No Hard Inquiry</h3>
			<p>Get prequalified without affecting your credit.</p>
			<h3>3 Months Interest Free*</h3>
			<p>Payoff your principal in 3 months and you pay no interest. You can also spread your payments out up to the term length provided in your offer.*</p>
			<h3>Approval Amounts</h3>
			<p>You can get approved for up to $5,000.00 and choose from multiple financing options.</p>
			<p style="font-size: 0.8em;">*Not all offers are 3 months interest free. Terms and conditions dependent upon the applicant’s credit profile.</p>
			<p>For more information, please visit <a href="https://credova.com/shoppersfaq" target="_blank">www.Credova.com/shoppersfaq</a></p>
		</div>
	</div>

</div>

<loading class="full-page" v-else>Loading Product...</loading>

</template>

<script>
export default {
	name: 'category',
	props: [ 'link_name' ],
	data() {
		return {
            loading: true,
            loadingRelatedProducts: false,
			addingToCart: false,
			hideOne: true,
			hideTwo: true,
			product: {},
			product_notices: [],
			relatedProducts: [],
			wishLists: [],
			inWishLists: [],
			wishListModalOpen: false,
			modalContent: "",
			showFinancingModal: false,
			showProductNotFound: false
		}
	},
	created() {
		setTimeout(() => {
			this.fetchData()
		}, 750)
	},
	mounted() {
		// If using Credova "Apply now" button
		// 1. Uncomment the script include code in public/index.php
		// 2. Uncomment html above
		// 3. Uncomment credova code inside apiCall() in store.js
		let interval = setInterval(() => {
			if (this.product && this.product.min_monthly_payment && this.product.credova_store_id) {
				clearInterval(interval)
				let config = {
					environment: (this.getIsDev) ? CRDV.Environment.Sandbox : CRDV.Environment.Production,
					store: this.product.credova_store_id
				}

				CRDV.plugin.config(config);
    			CRDV.plugin.inject("credova-button");

				// CRDV.plugin.config(config);
				// CRDV.plugin.addEventListener((e) => {
				// 	this.handleCredovaEvent(e)
				// });
				// if (this.getCredovaUserPublicId && !this.getCredovaApprovalAmount) {
				// 	this.checkCredovaAppStatus()
				// }
			}
		}, 0)
	},
	computed:{
		showBackToList() {
			// return_path was set in router.js
			return ['department', 'category', 'group', 'manufacturer', 'search'].some(substring => sessionStorage.getItem('return_path').includes(substring))
		},
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return

			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		}
	},
	methods: {
		fetchData() {
			// if (this.getEntryPath == this.$route.path) {
			// 	this.updateBtn('entry')
			// 	this.updateEntryPath('')
			// }
            this.loading = true
            this.loadingRelatedProducts = true
			this.apiCall({ destination: 'get_item_product_detail', data: { link_name: this.link_name, link_from: this.$route.query.link_from } }).then(obj => {
				this.loading = false
				if (obj.status == 'success' && obj.product) {
					this.product = obj.product
					this.product_notices = obj.product_notices
					this.wishLists = obj.wish_lists
					this.inWishLists = obj.in_wish_lists
					this.apiCall({ destination: 'get_list_related_products', data: { product_id: this.product.product_id, manufacturer_id: this.product.manufacturer_id, category_id: this.product.category_id, department_id: this.product.department_id } }).then(obj => {
						this.loadingRelatedProducts = false
						this.relatedProducts = obj.related_products
					}).catch(error => {
						console.log(error)
					})
				} else {
					this.loadingRelatedProducts = false
					this.message = obj.message
					this.showProductNotFound = true
				}
			}).catch(error => {
				this.loading = false
				console.log(error)
			})
		},
		addToCart() {
			if (this.addingToCart) {
				return
			}
			this.addingToCart = true
			this.apiCall({ destination: 'add_to_cart', data: { product_id: this.product.product_id, quantity: 1 } }).then(obj => {
				if (obj.status == 'success') {
					this.product.in_cart = true
        			this.fetchShoppingCartCount()
				}
				this.addingToCart = false
            }).catch(error => {
                console.log(error)
            })
		},
		inWishList(wishListId) {
			return this.inWishLists.includes(wishListId)
		},
		addToWishList(wishListId) {
			if (!wishListId) return
			this.apiCall({ destination: 'add_to_wish_list', data: { wish_list_id: wishListId, product_id: this.product.product_id }}).then(obj => {
				if (obj.status == 'success') {
					this.itemAdded(obj.wish_list)
				}
			}).catch(err => {
				console.log(err)
			})
		},
		itemAdded(wishList) {
			let currentWishLists = Object.keys(this.wishLists)
			if (currentWishLists.indexOf(wishList.wish_list_id) > -1) {
				this.inWishLists.push(parseInt(wishList.wish_list_id))
			} else {
				this.fetchData()
			}
			this.wishListModalOpen = false
		},
		goToWishList(wishList) {
			this.$router.push('/myaccount/lists/' + wishList)
		},
		backToList() {
			// this.updateBtn('back')
			this.$router.go(-1)
		},
		printWindow: function () {
			window.print();
		},
		// openPreAuthModal() {
		// 	CRDV.plugin.prequalify(this.product.price)
		// },
		handleCredovaEvent(event) {
			if (event.eventName === CRDV.EVENT_USER_WAS_APPROVED) {
				this.updateCredovaUserPublicId(event.eventArgs.publicId[0])
				this.checkCredovaAppStatus()
			}
		},
		checkCredovaAppStatus() {
			if (!this.getCredovaUserPublicId) return
			this.apiCall({ destination: 'check_credova_app_status', data: { public_id: this.getCredovaUserPublicId }}).then(obj => {
				if (obj.response && obj.response.approvalAmount) {
					this.updateCredovaApprovalAmount(obj.response.approvalAmount)
				}
			}).catch(err => {
				console.log(err);
			})
		}
	}
}
</script>

<style lang="scss">
.product {
	position: relative;
	.back-to-list {
		position: absolute;
		right: 0;
		margin-right: 1em;
		color: inherit;
		cursor: pointer;
	}
	.product-detail {
		grid-template-columns: 2.5fr 1fr;
		grid-gap: 2em;
		@media (max-width: 768px) {
			grid-template-columns: 1fr;
		}
		.last {
			h3:first-of-type {
				margin-top: 1em;
			}
			.price {
				font-size: 2.5em;
			}
		}
	}
}
.financing-available {
	font-size: 1em;
}
.credova-button {
	.crdv-button {
		background: none;
		color: inherit;
	}
}
.product.full-width {
	.product-detail {
		grid-template-columns: 65vw auto;
		@media (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}
}
.product-notices {
	font-size: 0.9em;
	margin: 0 0 1em 0;
}
.related-products {
	border-top: 1px solid;
	margin-top: 5em;
}
@media print {
	body { background-color: white !important; }
	.product { background-color: white;}
	.section, .related-products, .controls { display: none !important;}
	img { width: 50% !important; }
	.fa-plus { display: none !important; }
	.first, .last { width: 50%; }
	.tech-specs, .item-info {
		display: table !important;
		tr:nth-child(even) {background-color: #d8d8d8;}
	}
}
</style>
